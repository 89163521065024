.terms {
  font-family: 'Arial', sans-serif;
  padding: 40px;
  height: 100%;
  width: 100%;  line-height: 1.6;
  color: #333;
  background-color: rgba(245, 222, 179, 0.5);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;


  h2 {
      color: Black;
      text-align: center;
  }

  address {
      margin-top: 20px;
      margin-bottom: 40px;
      font-style: normal;
  }

  p {
      text-align: justify;
      margin-bottom: 20px;
  }

  p:first-child {
      margin-top: 0;
  }
}