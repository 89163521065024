.container {
    width: 80%;
    margin: auto;
    min-height: 100%;
}
.header {
    background-color: #003366;
    color: white;
    padding: 10px;
}
.header h1 {
    margin: 0;
}
.content-section {
    background: #ffffff;
    margin: 0px 0;
    padding: 0px;
    text-align: left;
}
.content-section h2 {
    color: #003366;
}
.content-section p {
    color: #333333;
    line-height: 1.6;
}
.footer {
    background-color: #003366;
    color: white;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
}