.privacy {
    font-family: 'Arial', sans-serif;
    padding: 40px;
    height: 100%;
    width: 100%;
    line-height: 1.6;
    color: #333;
    background-color: rgba(245, 222, 179, 0.5);
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

    h3 {
    color: #007BFF;
    text-align: center;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 15px;
    }

    p {
        text-align: justify;
        margin-bottom: 20px;

    }

    p:first-child {
        margin-top: 0;
    }
}