.feature-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Three columns */
    gap: 0px;
    padding: 5px;
}

.feature-card {

    background: #fff;
    border-radius: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 400px;
    margin: 10px;
}

.card-header {
    background-color: #0E0E0E;
    color: #E19A38;
    text-align: center;
    font-size: 1.2rem;
    justify-content: space-between;
    padding: 10px 0;
}

.card-content {
    padding: 11px;
    list-style-type: none;
    color: #333;
}

.card-content li {
    margin-bottom: 10px;
}



@media (max-width: 992px) {

    /* Adjust for smaller screens */
    .feature-container {
        grid-template-columns: 1fr 1fr;
        /* Two columns on smaller screens */
    }
}

@media (max-width: 600px) {

    /* Adjust for mobile screens */
    .feature-container {
        grid-template-columns: 1fr;
        /* One column on mobile screens */
    }
}


// new page 


.resource {
    font-family: "Lora", serif;
    // font-size: 60px;
    font-weight: 400;
    // line-height: 78px;
    text-align: center;
    padding-top: 1.5em;
    color: #11100F;
}

.resDetail {
    font-family: "Poppins", sans-serif;
    // font-size: 20px;
    // font-weight: 400;
    // line-height: 34px;
    text-align: center;
    margin-top: 14px;
    padding-bottom: 2em;
}

.boxwrapper {
    border: 1px solid #D0D3D6;
    background-color: #F8F9FB !important;
    border-radius: 20px !important;
    // height: 180px;
}

.bigboxwrapper {
    border: 1px solid #D0D3D6;
    // background-color: #F8F9FB !important;
    border-radius: 20px !important;
    // padding: 20px;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
}

.paperHead {
    font-family: "Lora", serif;
    // font-size: 44px;
    // font-weight: 600;
    // line-height: 57.2px;
    text-align: left;
    color: black !important;

}

.paperbody {
    font-family: "Poppins", sans-serif;
    // font-size: 20px;
    // font-weight: 400;
    // line-height: 32px;
    text-align: left;
    color: #2C2C2C;
}


.background {
    position: relative;
    // background-color: red;
}

.background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../common/icons/backgrounds/Group.svg');
    background-repeat: no-repeat;
    background-position: center;
    filter: opacity(0.3);
}